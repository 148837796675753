<template>
    <div class="loading-card flex-1">
        <div class="card__image"></div>
        <div class="card__content">
            <h2></h2>
            <p></p>
        </div>
    </div>
</template>

<style lang="scss">
    .loading-card {
        height: 100%;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        .card__image,
        h2,
        p {
            background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
            border-radius: 5px;
            background-size: 200% 100%;
            animation: 1s shine linear infinite;
        }
        .card__image {
            height: 200px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        .card__content {
            padding: 20px;
        }

        h2 {
            height: 30px;
        }

        p {
            margin-top: 2rem;
            height: 70px;
            width: 80%;
        }

        @keyframes shine {
            to {
                background-position-x: -200%;
            }
        }
    }
</style>
