
<template>
    <nav class="container py-6 md:py-12">
        <div class="flex flex-row flex-wrap justify-center items-center">
            <!-- Results Count
            <div class="flex-auto w-full text-center pb-3">
                <p class="text-base font-sans">Displaying {{ this.countStart }} &mdash; {{ this.countEnd }} of {{ this.total }} results</p>
            </div>
            -->

            <!-- Pages List -->
            <div class="flex-auto | inline-flex justify-center items-center gap-3">
                <!-- Previous Button -->
                <div :class="this.pageSectionClasses">
                    <button
                        :disabled="!this.hasPreviousPage"
                        :class="[this.hasPreviousPage ? 'hover:bg-gray-1' : '' , 'bg-white p-4 rounded-full']"
                        @click="this.updatePage(this.previousPage)"
                        type="button"
                        :data-page="this.previousPage"
                    >
                        <icon name="angle-left" :class="[this.hasPreviousPage ? 'fill-blue-default' : 'fill-blue-light-2', 'h-6 w-6']"/>
                    </button>
                </div>

                <!-- First -->
                <div :class="this.pageSectionClasses" v-if="this.staticCurrentPage != 1">
                    <button
                        :disabled="this.staticCurrentPage == 1"
                        :class="[
                            this.staticCurrentPage == 1
                            ? 'text-white bg-blue-default hover:font-bold'
                            : 'text-blue-default font-bold hover:bg-gray-1',
                            'rounded-full py-2 px-3 md:py-4 md:px-6 '
                        ]"
                        @click="this.updatePage(1)"
                        type="button"
                        data-page="1"
                    >
                        1
                    </button>
                </div>

                <!-- Ellipsis before previous -->
                <div :class="this.pageSectionClasses" v-if="(this.staticCurrentPage - this.neighbors) > 1">
                    <span class="pt-2 px-3 md:py-4 md:px-6 text-blue-default">&hellip;</span>
                </div>

                <!-- Previous pages -->
                <div :class="this.pageSectionClasses">
                    <button
                        v-if="this.previousPages && this.previousPage != 1"
                        v-for="page in this.previousPages"
                        :key="page"
                        class="text-blue-default py-2 px-3 md:py-4 md:px-6 rounded-full font-bold hover:bg-gray-1"
                        @click="this.updatePage(page)"
                        type="button"
                        :data-page="page"
                    >
                        {{ page }}
                    </button>
                </div>

                <!-- Current -->
                <div
                    :class="this.pageSectionClasses"
                    v-if="(this.staticCurrentPage != this.totalPages) || (this.totalPages == 1)"
                >
                    <button
                        disabled
                        class="text-white bg-blue-default hover:font-bold py-2 px-3.5 rounded-full md:py-4 md:px-6"
                    >
                        {{ this.staticCurrentPage }}
                    </button>
                </div>

                <!-- Next pages -->
                <div :class="this.pageSectionClasses">
                    <button
                        v-if="this.nextPages && this.nextPage != this.totalPages"
                        v-for="page in this.nextPages"
                        :key="page"
                        class="text-blue-default py-1 px-3 md:py-4 md:px-6 font-bold rounded-full hover:bg-gray-1"
                        @click="this.updatePage(page)"
                        type="button"
                        :data-page="page"
                    >
                        {{ page }}
                    </button>
                </div>

                <!-- Ellipsis after next -->
                <div :class="this.pageSectionClasses" v-if="(this.totalPages - this.staticCurrentPage) > (this.neighbors + 1)">
                    <span class="py-2 px-3 md:py-4 md:px-6 text-blue-default">&hellip;</span>
                </div>

                <!-- Last -->
                <div :class="this.pageSectionClasses" v-if="this.totalPages != 1">
                    <button
                        :class="[
                            this.staticCurrentPage == this.totalPages
                            ? 'text-white bg-blue-default hover:font-bold'
                            : 'text-blue-default font-bold hover:bg-gray-1',
                            'py-2 px-3 rounded-full md:py-4 md:px-6 '
                        ]"
                        @click="this.updatePage(this.totalPages)"
                        type="button"
                        :data-page="this.totalPages"
                    >
                        {{ this.totalPages }}
                    </button>
                </div>

                <!-- Next Button -->
                <div :class="this.pageSectionClasses">
                    <button
                        :disabled="!this.hasNextPage"
                        :class="[this.hasNextPage ? 'hover:bg-gray-1' : '' , 'bg-white p-4 rounded-full']"
                        @click="updatePage(this.nextPage)"
                        type="button"
                        :data-page="this.nextPage"
                    >
                        <icon name="angle-right" :class="[this.hasNextPage ? 'fill-blue-default' : 'fill-blue-light-2', 'h-6 w-6']"/>
                    </button>
                </div>
            </div>
        </div>
    </nav>
</template>

<style></style>

<script>
import Icon from './Icon.vue';

export default {
    emits: ['paginate'],
    props: {
        currentPage: {
            type: Number,
            require: true,
            default: 1
        },
        total: {
            type: Number,
            require: true,
            default: 1
        },
        count: {
            type: Number,
            require: true,
            default: 1
        },
        perPage: {
            type: Number,
            require: true,
            default: 1
        },
        totalPages: {
            type: Number,
            require: true,
            default: 1
        },
        previousLink: {
            type: String,
            require: true,
            default: ''
        },
        nextLink: {
            type: String,
            require: true,
            default: ''
        }
    },
    components: {
        Icon
    },
    directives: {},
    methods: {
        updatePage(page) {
            let pageNumber = parseInt(page);
            this.$emit('paginate', pageNumber);
        }
    },
    data() {
        return {
            neighbors: 1,
            hasNextPage: this.nextLink != '',
            hasPreviousPage: this.currentPage > 1,
            nextPage: 1,
            previousPage: 1,
            nextPages: [],
            previousPages: [],
            countStart: 1,
            countEnd: 1,
            pageSectionClasses: 'flex-initial inline-flex gap-3 justify-center items-center',
            staticCurrentPage: this.currentPage
        }
    },
    mounted() {
        // Create the next pages
        if (this.currentPage != this.totalPages) {
            let nextPages = [];
            let maxPages = this.totalPages > (this.currentPage + this.neighbors) ? (this.currentPage + this.neighbors) : this.totalPages - 1;

            for(let i = this.currentPage + 1; i <= maxPages; i++) {
                nextPages.push(i);
            }
            this.nextPages = nextPages;
        }
        this.nextPage = this.hasNextPage ? parseInt(this.currentPage) + 1 : 1;

        // Create the previous pages
        if (this.currentPage > 1) {
            let previousPages = [];
            for (let i = this.currentPage - 1; i >= this.currentPage - this.neighbors; i--) {
                if (i > 1) {
                    previousPages.push(i);
                }
            }
            this.previousPages = previousPages.reverse();
        }
        this.previousPage = this.hasPreviousPage ? parseInt(this.currentPage) - 1 : 1;

        // Get the totals
        this.countEnd = this.count < this.perPage ? this.total : this.count * this.currentPage;
        this.countStart = this.hasPreviousPage ? (this.perPage * this.previousPage) + 1 : 1;
    }
}
</script>
